import { MainContentPanel } from '../../components/MainContentPanel';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner';
import makeStyles from '@mui/styles/makeStyles';

import { useQuery } from '@apollo/client';
import { GET_LICENSES_WHERE_IS_ADMIN } from '../user.graphql';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

export function UserAdministratorCard({ userId }: { userId: number }) {
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Administrerer
      </Typography>
      <Licenses userId={userId} />
    </MainContentPanel>
  );
}

function Licenses({ userId }: { userId: number }) {
  const classes = useStyles();
  const { loading, error, data } = useQuery<{ licenses: { id: number; companyName: string }[] }>(GET_LICENSES_WHERE_IS_ADMIN, {
    variables: { id: userId },
  });

  if (loading) return <LoadingSpinner />;
  if (error) {
    console.error(error.message);
    return <span>Feil! {error.message}</span>;
  }

  if (!data) return null;
  const list = data.licenses;
  if (list.length === 0)
    return (
      <Typography variant="caption" className={classes.disabled}>
        Administrerer ingen lisenser
      </Typography>
    );

  return (
    <List dense>
      {list.map((x) => (
        <ListItem key={x.id}>
          <ListItemText>
            <Link to={`/license/${x.id}`}>{x.companyName || '<Mangler navn>'}</Link>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

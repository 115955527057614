import makeStyles from '@mui/styles/makeStyles';
import { Grid, TableHead, TableCell, TableBody, Table, TableRow, List, Stack, Switch, FormControlLabel } from '@mui/material';
import { MainContentPanel } from '../components/MainContentPanel';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { WidgetTitle } from '../dashboard/WidgetTitle';
import LoadingSpinner from '../components/LoadingSpinner';
import StarIcon from '@mui/icons-material/Star';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from 'react-router-dom';

const QUERY = gql`
  query ($monthOffset: Int!, $onlyNew: Boolean!, $includeStudents: Boolean!) {
    lisenses: getNewSubscriptions(monthOffset: $monthOffset, onlyNew: $onlyNew, includeStudents: $includeStudents) {
      licenseId
      name
      isNew
      isStudent
      subscriptionsAdded
    }
  }
`;

export const useStyles = makeStyles((theme) => ({
  newStar: {
    marginLeft: 5,
    verticalAlign: 'middle',
    color: 'gold',
  },
  studentIcon: {
    marginLeft: 5,
    verticalAlign: 'middle',
    color: 'grey',
  },
}));

interface License {
  licenseId: number;
  name: string;
  isNew: boolean;
  isStudent: boolean;
  subscriptionsAdded: string;
}

export function NewSubscriptions(props) {
  const classes = useStyles();

  var take = 100;

  const [monthOffset, setMonthOffset] = useState(Number(props.offset));

  const [onlyNew, setonlyNew] = useState(false);
  const [includeStudents, setincludeStudents] = useState(false);

  const { loading, data } = useQuery(QUERY, {
    variables: { monthOffset: monthOffset, take: take, onlyNew: onlyNew, includeStudents: includeStudents },
    fetchPolicy: 'network-only',
  });

  return (
    <MainContentPanel>
      <Grid container>
        <Grid item xs={5}>
          <WidgetTitle
            title="Nye kunder og brukerlisenser i"
            offset={monthOffset}
            stateSetter={(step) => {
              setMonthOffset(monthOffset + step);
            }}
          />

          <List component={Stack} direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                control={<Switch onChange={() => setonlyNew(!onlyNew)} checked={onlyNew} />}
                label={
                  <span>
                    Kun nye
                    <StarIcon className={classes.newStar} />
                  </span>
                }
              />
              <FormControlLabel
                control={<Switch onChange={() => setincludeStudents(!includeStudents)} checked={includeStudents} />}
                label={
                  <span>
                    Inkluder studenter
                    <SchoolIcon className={classes.studentIcon} />
                  </span>
                }
              />
            </Stack>
          </List>

          {loading && <LoadingSpinner />}
          {!loading && !data && <div>Mangler data</div>}
          {data && renderDetails(data.lisenses)}
        </Grid>
      </Grid>
    </MainContentPanel>
  );

  function renderDetails(licenses) {
    return (
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell align="right">Nye brukerlisenser</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {licenses.map((license: License) => {
            return (
              <TableRow key={license.licenseId}>
                <TableCell>
                  <Link to={`/license/${license.licenseId}`}>{license.name}</Link>
                  {license.isNew ? (
                    <span>
                      <StarIcon className={classes.newStar} />
                    </span>
                  ) : (
                    ''
                  )}
                  {license.isStudent ? (
                    <span>
                      <SchoolIcon className={classes.studentIcon} />
                    </span>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell align="right">{license.subscriptionsAdded}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

import { TableCell, TableBody, Table, TableRow } from '@mui/material';
import { WidgetPanel } from './WidgetPanel';
import { WidgetTitle } from './WidgetTitle';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { numberFormatted } from '../Formatters';

const QUERY = gql`
  query ($monthOffset: Int!) {
    summary: getNewSubscriptionsSummary(monthOffset: $monthOffset) {
      newLicenses
      newUsers {
        subscription
        name
        userCount
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  total: {
    fontWeight: 'bold',
  },
  panel: {},
  divider: {
    marginTop: 5,
  },
  noBorderBottom: {
    borderBottom: 'none',
  },
}));

export function NewSubscriptionsSummary() {
  const classes = useStyles();

  const [monthOffset, setMonthOffset] = useState(0); // get statistics for current month by default

  const { data } = useQuery(QUERY, { variables: { monthOffset: monthOffset } });

  const LinkToDetails = (props) => (
    <Link to={`/report?tab=6&offset=${props.offset}`} title={props.title}>
      {props.children}
    </Link>
  );

  return (
    <div className={classes.panel}>
      <WidgetPanel>
        <WidgetTitle
          title="Nye kunder og brukere i"
          offset={monthOffset}
          forwardLimit={1}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {!data && <LoadingSpinner />}
        {data && (
          <Table padding="none">
            <TableBody>
              <TableRow>
                <TableCell>Nye kunder:</TableCell>
                <TableCell align="right">{data.summary.newLicenses}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.noBorderBottom} colSpan={2}>
                  &nbsp;
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Nye brukere:</TableCell>
              </TableRow>
              {data.summary.newUsers.map((n, i) => (
                <TableRow key={i}>
                  <TableCell>{n.name}:</TableCell>
                  <TableCell align="right">{numberFormatted(n.userCount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <LinkToDetails offset={monthOffset}>Se mer</LinkToDetails>
      </WidgetPanel>
    </div>
  );
}

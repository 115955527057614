import React, { useState } from 'react';
import { MainContentPanel } from '../../components/MainContentPanel';
import { Typography, Tabs, Tab } from '@mui/material';
import { UsersInSubscription } from './UsersInSubscription';
import { GET_SUBSCRIPTIONS } from './UsersInLicense.graphql';

import LoadingSpinner from '../../components/LoadingSpinner';
import { LicenseSubscription } from '../../interfaces';
import { AddUsers } from './AddUsers';
import { SecuritySettings } from './SecuritySettings';
import { useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
}

export function UsersInLicense({ licenseId }: Props) {
  const { loading, error, data } = useQuery<{ subscriptions: LicenseSubscription[] }, { id: number }>(GET_SUBSCRIPTIONS, {
    variables: { id: licenseId },
  });
  if (error)
    return (
      <UsersPanel>
        <pre>`Uffda! ${error.message}`</pre>
      </UsersPanel>
    );
  if (loading || !data)
    return (
      <UsersPanel>
        <LoadingSpinner />
      </UsersPanel>
    );

  if (data.subscriptions.length === 0) return null;

  return (
    <UsersPanel subscriptions={data.subscriptions}>
      <SubscriptionsTabs subscriptions={data.subscriptions} />
    </UsersPanel>
  );
}

interface TabsProps {
  subscriptions: LicenseSubscription[];
}

function SubscriptionsTabs({ subscriptions }: TabsProps) {
  const [value, setValue] = useState(0);
  return (
    <div>
      <Tabs value={value} onChange={(ev, val) => setValue(val)}>
        {subscriptions.map((x) => (
          <Tab key={x.subscription.id} label={x.subscription.name} />
        ))}
      </Tabs>
      {subscriptions.map((x, index) => {
        return value === index && <UsersInSubscription key={x.subscription.id} subscription={x} />;
      })}
    </div>
  );
}

function UsersPanel({ subscriptions, children }: { subscriptions?: LicenseSubscription[]; children: any }) {
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        <span>Brukere</span>
        {subscriptions && <AddUsers subscriptions={subscriptions} />}
        {subscriptions && <SecuritySettings subscriptions={subscriptions} />}
      </Typography>
      {children}
    </MainContentPanel>
  );
}

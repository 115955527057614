import React, { useState } from 'react';
import { Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { MainContentPanel } from '../../components/MainContentPanel';
import { useConfig } from '../../components/Configuration';
import { gql, useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Mail } from '@mui/icons-material';
import { formatDate } from '../../Formatters';

/* Rel */

interface Props {
  licenseId: number;
}

interface Notification {
  id: string;
  subject: string;
  sentAt: string;
}

let query = gql`
  query ($licenseId: Int!, $offset: Int, $limit: Int) {
    queryResult: getNotifications(licenseId: $licenseId, offset: $offset, limit: $limit) {
      totalCount
      notifications {
        id
        subject
        sentAt
      }
    }
  }
`;

/* Rel */
export function Notifications({ licenseId }: Props) {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const [limit, setLimit] = useState(5);
  const rootUrl = config ? config.apiUrl : '/';

  const { loading, data, refetch } = useQuery<{ queryResult: { totalCount: number; notifications: Notification[] } }>(query, {
    variables: { licenseId, limit },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data && data.queryResult.notifications.length < data.queryResult.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };
  return (
    <MainContentPanel>
      <Typography variant="h5">Genererte e-poster</Typography>
      {(!data || loading) && <LoadingSpinner />}
      {data && <NotificationList licenseId={licenseId} notifications={data.queryResult.notifications} rootUrl={rootUrl} />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </MainContentPanel>
  );
}

export function NotificationList({ licenseId, notifications, rootUrl }: { licenseId: number; notifications: Notification[]; rootUrl: string }) {
  if (!notifications) return null;

  return (
    <List>
      {notifications.map((x, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <MailLink licenseId={licenseId} notificationId={x.id} rootUrl={rootUrl} />
          </ListItemIcon>
          <ListItemText primary={x.subject} secondary={`${formatDate(x.sentAt)}`} />
        </ListItem>
      ))}
    </List>
  );
}

function MailLink({ licenseId, notificationId, rootUrl }: { licenseId: number; notificationId: string; rootUrl: string }) {
  return (
    <a title="Åpne mail ny fane" href={rootUrl + '/notification/' + licenseId + '?notificationId=' + notificationId} target="_blank" rel="noreferrer">
      <Mail />
    </a>
  );
}

import { gql } from '@apollo/client';
import { DiscountItem } from '../../interfaces.graphql';

export const GET_DISCOUNTS_ON_LICENSE = gql`
  query ($id: Int!) {
    discounts: getDiscountsByLicenseId(licenseId: $id) {
      ...DiscountItem
    }
  }
  ${DiscountItem}
`;

export const ADD_DISCOUNT = gql`
  mutation addDiscount($input: AddDiscountInput!) {
    addDiscount(input: $input) {
      id
      bksId
      pendingTermination
      discounts
    }
  }
`;

export const REMOVE_DISCOUNT = gql`
  mutation removeDiscount($input: RemoveDiscountInput!) {
    removeDiscount(input: $input) {
      id
      bksId
      pendingTermination
      discounts
    }
  }
`;

import { gql } from '@apollo/client';
import { UserItem } from '../../interfaces.graphql';

const LicenseItemComposite = gql`
  fragment LicenseContactItem on LicenseContactModel {
    license {
      id
      pendingContact
    }
    bksLicense {
      id
      contact {
        ...UserItem
      }
    }
  }
  ${UserItem}
`;

export const GET_CONTACT = gql`
  query ($id: Int!) {
    licenseContact: licenseContact(licenseId: $id) {
      ...LicenseContactItem
    }
  }
  ${LicenseItemComposite}
`;

export const UPDATE_CONTACT = gql`
  mutation ($input: UpdateContactInput!) {
    updateContact(input: $input) {
      ...LicenseContactItem
    }
  }
  ${LicenseItemComposite}
`;

export const CANCEL_UPDATE_CONTACT = gql`
  mutation ($input: CancelUpdateContactInput!) {
    cancelUpdateContact(input: $input) {
      ...LicenseContactItem
    }
  }
  ${LicenseItemComposite}
`;

import { gql } from '@apollo/client';

const ModelItem = gql`
  fragment SubscriptionModelItem on SubscriptionModel {
    id
    bksId
    name
    parentId
    price {
      singleUser
      extraUser
    }
    pendingPriceChange {
      id
      timeOfChange
      newPrice {
        singleUser
        extraUser
      }
    }
  }
`;

export let GET_SUBSCRIPTIONS = gql`
  query {
    subscriptions: getSubscriptions {
      ...SubscriptionModelItem
    }
  }
  ${ModelItem}
`;

export const UPDATE_PRICE = gql`
  mutation ChangeSubscriptionPrice($input: ChangeSubscriptionPriceInput!) {
    changeSubscriptionPrice(input: $input) {
      ...SubscriptionModelItem
    }
  }
  ${ModelItem}
`;

export const CANCEL_PRICE_UPDATE = gql`
  mutation CancelSubscriptionPriceChange($input: CancelSubscriptionPriceChangeInput!) {
    cancelSubscriptionPriceChange(input: $input) {
      ...SubscriptionModelItem
    }
  }
  ${ModelItem}
`;

export const GET_CHANGES = gql`
  query ($offset: Int, $limit: Int) {
    queryResult: getSubscriptionChanges(offset: $offset, limit: $limit) {
      totalCount
      changes {
        id
        isExternal
        description
        createdAt
        createdBy
      }
    }
  }
`;

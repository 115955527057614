import React from 'react';
import { GET_USELOG_BY_LICENSE } from '../reports.graphql';
import { useQuery } from '@apollo/client';
import { Table, TableHead, TableRow, TableCell, Typography, Paper, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoadingSpinner from '../../components/LoadingSpinner';
import Grid from '@mui/material/Grid';
import XLSX from 'xlsx';
import { ArrowBack } from '@mui/icons-material';
import ExcelLogo from '../../assets/microsoft-excel.svg?react';

interface UseLogModel {
  numberOfVisit: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  medium: string;
  displayName: string;
}
const useStyles = makeStyles((theme) => ({
  fitwidth: {
    width: 1,
    whiteSpace: 'nowrap' as 'nowrap',
  },
}));
const renderBreadCrumb = (data, callback, selectedGuide, customerName) => {
  return (
    <>
      <Paper elevation={3}>
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item>
            <Typography gutterBottom variant="subtitle1">
              <IconButton onClick={callback} title="Go bak til kunde liste" size="large">
                <ArrowBack />
              </IconButton>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" gutterBottom>
              Anvisning:{selectedGuide}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" gutterBottom>
              Kunde:{customerName}
            </Typography>
          </Grid>
          <Grid item>{data && <UseLogTableExcelExport model={data.uselogs} />}</Grid>
        </Grid>
      </Paper>
    </>
  );
};
const renderTable = (data, classes, loading) => {
  return (
    <>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bruker navn</TableCell>
              <TableCell className={classes.fitwidth}>Antall søk</TableCell>
              <TableCell>Logget inn med</TableCell>
            </TableRow>
          </TableHead>
          {data &&
            data.uselogs.map((x) => {
              return (
                <TableRow key={x.userId}>
                  <TableCell>{x.displayName}</TableCell>
                  <TableCell>{x.numberOfVisit}</TableCell>
                  <TableCell>{x.medium}</TableCell>
                </TableRow>
              );
            })}
        </Table>
        {loading && <LoadingSpinner />}
      </Paper>
    </>
  );
};
export default function UseLogTable(props) {
  const { guideId, customerId, fromDate, toDate, callback, customerName, selectedGuide, email } = props;
  const { loading, data } = useQuery<{ uselogs: UseLogModel[] }>(GET_USELOG_BY_LICENSE, {
    variables: { licenseId: customerId, guideId: guideId, fromDate: fromDate, toDate: toDate, email: email },
  });
  const classes = useStyles();
  return (
    <>
      {renderBreadCrumb(data, callback, selectedGuide, customerName)}
      {renderTable(data, classes, loading)}
    </>
  );
}
function UseLogTableExcelExport({ model }: { model: UseLogModel[] }) {
  const exportFile = () => {
    const workbook = XLSX.utils.book_new();
    const heading: string[] = ['Bruker navn', 'Antall søk', 'Logget inn med'];
    const workBookName = 'Antall søk per bruker';
    const data = model.map((x) => [x.displayName, x.numberOfVisit, x.medium]);
    const input = [heading, ...data];
    const sheet = XLSX.utils.aoa_to_sheet(input);
    XLSX.utils.book_append_sheet(workbook, sheet, workBookName);
    XLSX.writeFile(workbook, 'brukantall.xlsx');
  };
  return (
    <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
      <ExcelLogo />
    </IconButton>
  );
}

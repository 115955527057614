import { gql } from '@apollo/client';

export const AddressItem = gql`
  fragment AddressItem on Address {
    id
    address1
    address2
    city
    postalCode
    country
  }
`;

export const UserItem = gql`
  fragment UserItem on User {
    id
    loginId
    firstName
    lastName
    displayName
    email
    phone
    lastLoggedIn
    isAdminuserOnActiveLicense
    singleDocumentAccesses {
      doc {
        docID
        docName
      }
      validFrom
      validTo
    }
    address {
      ...AddressItem
    }
  }
  ${AddressItem}
`;

export const LicenseItem = gql`
  fragment LicenseItem on License {
    id
    isActive
    isLocked
    isPersonal
    companyName
    orgNumber
    email
    phone
    customerType
    providerId
    address {
      ...AddressItem
    }
    maconomy {
      customerNumber
    }
    singleDocumentAccesses {
      docId
      validFrom
      validTo
    }
    licenseStatus
    agreementExpire
    contactId
  }
  ${AddressItem}
`;

export const LicenseModelItem = gql`
  fragment LicenseModelItem on LicenseModel {
    id
    bksId
    invoiceRef
    lockedReason
    terminationReason
    terminatedAt
    pendingTermination
    discounts
    requireTwoFactorAuthentication
    adfsScheme
    isGodkjentVaatromsbedrift
  }
`;

export const CustomerItem = gql`
  fragment CustomerItem on MaconomyCustomerModel {
    customerNumber
    companyName
    orgNumber
    email
    phone
    address {
      ...AddressItem
    }
  }
  ${AddressItem}
`;

export const CustomerByLicenseItem = gql`
  fragment CustomerByLicenseItem on MaconomyCustomerByLicenseModel {
    customerNumber
    companyName
    orgNumber
    email
    phone
    address {
      ...AddressItem
    }
    hasSameDataAsUs
  }
  ${AddressItem}
`;

export const UserAccessItem = gql`
  fragment UserAccessItem on UserAccess {
    id
    createdAt
    user {
      ...UserItem
    }
  }
  ${UserItem}
`;

export const OrderLineItem = gql`
  fragment OrderLineItem on OrderLine {
    itemId
    numberOfAccesses
    amount
    discount
    description
  }
`;

export const SingleDocOrderLineItem = gql`
  fragment SingleDocOrderLineItem on SingleDocOrderLine {
    docId
    docName
    title
    price
    priceIncVat
    discount
    validFrom
    validTo
  }
`;

export const DiscountOverviewItem = gql`
  fragment DiscountOverviewItem on DiscountOverviewModel {
    id
    name
    value
    type
    isApplicable
    emailFilter
    validFrom
    validTo
    usages
  }
`;

export const DiscountItem = gql`
  fragment DiscountItem on DiscountModel {
    id
    name
    value
    type
    allowRenewal
    maxUsageCount
    onlyFirstYear
    subscriptions {
      subscriptionId
      numberOfAccesses
    }
    pendingValueUpdate {
      id
      timeOfChange
      value
    }
    isActive
    isApplicable
    emailFilter
    confirmUrlAction
    validFrom
    validTo
    usages
  }
`;
export const AdfsItem = gql`
  fragment AdfsItem on ADFSModel {
    id
    scheme
    emailDomains
    displayName
    authority
    bksID
    extraUserPercentage
    isRestricted
    clientID
    clientSecret
    callbackPath
    expiryDate
    tenantID
    clientAuthorizations {
      clientAuthorizationID
      requiredClaims {
        type
        value
      }
    }
  }
`;

export const LicenseAdfsItem = gql`
  fragment LicenseAdfsItem on LicenseAdfsModel {
    bksID
    scheme
  }
`;

export const ClientAuthorizationItem = gql`
  fragment ClientAuthorizationItem on ADFSModel {
    id
    clientAuthorizations {
      clientAuthorizationID
      requiredClaims {
        type
        value
      }
    }
  }
`;

import { gql } from '@apollo/client';
import { LicenseItem, AddressItem, LicenseModelItem } from '../../interfaces.graphql';

export const GET_LICENSE = gql`
  query ($id: Int!) {
    license: license(licenseId: $id) {
      bksLicense {
        ...LicenseItem
      }
      license {
        ...LicenseModelItem
      }
      isInvoicable
    }
  }
  ${LicenseItem}
  ${LicenseModelItem}
`;

export const UPDATE_NAME = gql`
  mutation licenseUpdateCompanyName($input: LicenseUpdateCompanyNameInput!) {
    licenseUpdateCompanyName(input: $input) {
      id
      companyName
    }
  }
`;

export const UPDATE_ORGNUMBER = gql`
  mutation licenseUpdateOrgNumber($input: LicenseUpdateOrgNumberInput!) {
    licenseUpdateOrgNumber(input: $input) {
      id
      orgNumber
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation licenseUpdateEmail($input: LicenseUpdateEmailInput!) {
    licenseUpdateEmail(input: $input) {
      id
      email
    }
  }
`;

export const UPDATE_PHONE = gql`
  mutation licenseUpdatePhone($input: LicenseUpdatePhoneInput!) {
    licenseUpdatePhone(input: $input) {
      id
      phone
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation licenseUpdateAddress($input: LicenseUpdateAddressInput!) {
    licenseUpdateAddress(input: $input) {
      id
      address {
        ...AddressItem
      }
    }
  }
  ${AddressItem}
`;

export const UPDATE_INVOICEREF = gql`
  mutation licenseUpdateInvoiceRef($input: LicenseUpdateInvoiceRefInput!) {
    licenseUpdateInvoiceRef(input: $input) {
      id
      invoiceRef
    }
  }
`;

export const RENEW = gql`
  mutation triggerRenewal($input: TriggerRenewalInput!) {
    triggerRenewal(input: $input)
  }
`;

export const LOCK = gql`
  mutation lockLicense($input: LockLicenseInput!) {
    lockLicense(input: $input) {
      bksLicense {
        id
        isLocked
      }
      license {
        id
        lockedReason
      }
    }
  }
`;

export const UNLOCK = gql`
  mutation unlockLicense($input: UnlockLicenseInput!) {
    unlockLicense(input: $input) {
      bksLicense {
        id
        isLocked
      }
      license {
        id
        lockedReason
      }
    }
  }
`;
export const TERMINATE = gql`
  mutation terminateLicense($input: TerminateLicenseInput!) {
    terminateLicense(input: $input) {
      id
      pendingTermination
      terminationReason
    }
  }
`;

export const CANCEL_TERMINATE = gql`
  mutation cancelTerminateLicense($input: CancelTerminateLicenseInput!) {
    cancelTerminateLicense(input: $input) {
      id
      pendingTermination
      terminationReason
    }
  }
`;
export const START_SUSPEND_EMAL = gql`
  mutation startLicenseSuspendEmail($input: StartLicenseSuspendEmailInput!) {
    startLicenseSuspendEmail(input: $input) {
      id
    }
  }
`;
export const END_SUSPEND_EMAIL = gql`
  mutation endLicenseSuspendEmail($input: EndLicenseSuspendEmailInput!) {
    endLicenseSuspendEmail(input: $input) {
      id
    }
  }
`;

export const UPDATE_DOC_ACCESS = gql`
  mutation updateDocAccess($input: UpdateDocAccessInput!) {
    updateDocAccess(input: $input)
  }
`;

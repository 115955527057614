import { gql } from '@apollo/client';

const LicenseModelItem = gql`
  fragment LicenseModelItem2 on LicenseModel {
    id
    bksId
    pendingTermination
    discounts
  }
`;

const LicenseItem = gql`
  fragment LicenseItem2 on License {
    id
    companyName
    orgNumber
    isActive
    isLocked
    maconomy {
      customerNumber
    }
    contact {
      id
      displayName
    }
    createdAt
  }
`;

export const GET_LICENSES = gql`
  query (
    $series: Int
    $onlyActive: Boolean
    $onlyLocked: Boolean
    $onlyADFS: Boolean
    $onlyPendTerm: Boolean
    $onlyInformed: Boolean
    $getColumn: [String]
  ) {
    licenses: allLicenses(
      series: $series
      onlyActive: $onlyActive
      onlyLocked: $onlyLocked
      onlyPendTerm: $onlyPendTerm
      onlyADFS: $onlyADFS
      onlyInformed: $onlyInformed
      getColumn: $getColumn
    ) {
      id
      companyName
      orgNumber
      customerNumber
      administrator
      isGodkjentVaatromsbedrift
      isDeleted
      createdAt
      address {
        address1
        address2
        postalCode
        city
        country
      }
      invoiceRef
      subscriptions
      usersPerSubscriptions {
        subscriptionId
        numberOfUsers
      }
      licensesPerSubscription {
        subscriptionId
        numberOfUsers
      }
      code
      codeName
      numberOfEmployees
      totalPageViews
      lockedAt
    }
  }
`;

export const CREATE_LICENSE = gql`
  mutation CreateLicense($input: CreateLicenseInput!) {
    createLicense(input: $input) {
      bksLicense {
        ...LicenseItem2
      }
      license {
        ...LicenseModelItem2
      }
    }
  }
  ${LicenseModelItem}
  ${LicenseItem}
`;

export const ALL_SUBSCRIPTIONS = gql`
  query {
    subscriptions: allSubscriptions(onlyPaid: true) {
      id
      name
      number
      parentId
    }
  }
`;

import { MainContentPanel } from '../../components/MainContentPanel';
import { Typography } from '@mui/material';
import ADFSList from './ADFSList';

export function ADFSOverview(props) {
  return (
    <MainContentPanel>
      <Typography variant="h5">ADFS Provider</Typography>
      <ADFSList />
    </MainContentPanel>
  );
}

import React, { FC } from 'react';
import { Paper } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export const MainContentPanel: FC<{ children: React.ReactNode }> = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <ErrorBoundary fallback={<div>Noe gikk galt!</div>}>{children}</ErrorBoundary>
    </Paper>
  );
};

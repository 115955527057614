import { FC } from 'react';
import { MainContentPanel } from '../components/MainContentPanel';
import { RenewalNavigation } from './RenewalNavigation';
import { RenewalContextProvider } from './RenewalContext';
import { RenewalTable } from './Table/RenewalTable';
import { Stack } from '@mui/material';
import { SummaryOverview } from './Summary/SummaryOverview';

export const RenewalOverview: FC = () => {
  return (
    <MainContentPanel>
      <RenewalContextProvider>
        <Stack spacing={2}>
          <RenewalNavigation />
          <SummaryOverview />
          <RenewalTable />
        </Stack>
      </RenewalContextProvider>
    </MainContentPanel>
  );
};

import { MainContentPanel } from '../components/MainContentPanel';
import { GET_USERS_WITH_HIGH_USAGE } from './reports.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/LoadingSpinner';
import { Grid, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { WidgetTitle } from '../dashboard/WidgetTitle';
import { useState } from 'react';

interface User {
  userId: string;
  name: string;
  licenseId: string;
  companyName: string;
  views: string;
}

export function UserPageViews(props) {
  const [monthOffset, setMonthOffset] = useState(Number(props.offset));

  var take = 100;

  const { loading, data } = useQuery(GET_USERS_WITH_HIGH_USAGE, { variables: { monthOffset: monthOffset, take: take }, fetchPolicy: 'network-only' });

  return (
    <MainContentPanel>
      <Grid container>
        <Grid item xs={5}>
          <WidgetTitle
            title="Brukeraktivitet for"
            offset={monthOffset}
            stateSetter={(step) => {
              setMonthOffset(monthOffset + step);
            }}
            displayYear={true}
            forwardLimit={1}
          />

          {loading && <LoadingSpinner />}
          {!loading && !data && <div>Mangler data</div>}
          {data && renderDetails(data.users)}
        </Grid>
      </Grid>
    </MainContentPanel>
  );

  function renderDetails(users) {
    return (
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Firma</TableCell>
            <TableCell align="right">Antall sidevisninger</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: User) => {
            return (
              <TableRow key={user.userId}>
                <TableCell>
                  <Link to={`/user/${user.userId}`}>{user.name}</Link>
                </TableCell>
                <TableCell>
                  <Link to={`/license/${user.licenseId}`}>{user.companyName}</Link>
                </TableCell>
                <TableCell align="right">{user.views}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

import { gql } from '@apollo/client';

const RenewalItem = gql`
  fragment RenewalModelItem on RenewalModel {
    id
    licenseId
    name
    subscriptionOrderNumber
    bfs
    bfsp
    bfsb
    bfsf
    bfsu
    bvn
    total
  }
`;

export const GET_RENEWALS_AT = gql`
  query ($month: Int!, $year: Int!, $at: String!) {
    renewals: getRenewalsAt(month: $month, year: $year, at: $at) {
      ...RenewalModelItem
    }
  }
  ${RenewalItem}
`;

export const GET_RENEWALS_MACONOMY = gql`
  query ($month: Int!, $year: Int!) {
    renewals: getRenewalsFromMaconomy(month: $month, year: $year) {
      ...RenewalModelItem
    }
  }
  ${RenewalItem}
`;

import XLSX from 'xlsx';
import makeStyles from '@mui/styles/makeStyles';
import { ISettings, LicenseOverviewModel, LicenseSubscriptiondata, showColumn, SubscriptionName } from './ColumnDefinitions';
import { IconButton } from '@mui/material';
import { formatDateOnly } from '../../Formatters';
import ExcelLogo from '../../assets/microsoft-excel.svg?react';

const useStyles = makeStyles((theme) => ({
  excelButton: {},
  excelIcon: {
    width: 24,
    height: 24,
  },
}));

export function ExcelExport({ items, columnlist }: { items: LicenseOverviewModel[]; columnlist: ISettings[] }) {
  const classes = useStyles();
  return (
    <IconButton className={classes.excelButton} onClick={() => exportFile(items, columnlist)} title="Eksport til Excel" disabled={!items}>
      <ExcelLogo className={classes.excelIcon} />
    </IconButton>
  );
}

//const getNumber = (numberString: string|undefined) =>numberString ? parseInt(numberString.replace(/\u00a0/g, ''), 10) : '';

function exportFile(items: LicenseOverviewModel[], columnlist: ISettings[]) {
  const headings: string[] = [];

  columnlist.filter((c) => c.showColumn === true).forEach((c) => expandColumns(c, headings));

  headings.unshift('Id');

  // We need to convert to numbers, to enable sorting etc in Excel.
  // To parse, remove group separator, which is a non-breaking space (Unicode U+00A0) in nb-NO.

  const data: (string | number | null)[][] = items.map((item) => {
    var a: (string | number | null)[] = [];

    a.push(item.id);
    if (showColumn('companyName', columnlist)) a.push(item.companyName ? item.companyName : '<Mangler navn>');
    if (showColumn('orgNumber', columnlist)) a.push(item.orgNumber);
    if (showColumn('customerNumber', columnlist)) a.push(item.customerNumber);
    if (showColumn('administrator', columnlist)) a.push(item.administrator);
    if (showColumn('createdAt', columnlist)) a.push(formatDateOnly(item.createdAt));
    if (showColumn('invoiceRef', columnlist)) a.push(item.invoiceRef!);
    if (showColumn('address1', columnlist)) a.push(item.address === null ? '' : item.address?.address1!);
    if (showColumn('subscriptions', columnlist))
      a.push(item.subscriptions !== null ? item.subscriptions!.map((c) => SubscriptionName(c)).join(',') : '');
    if (showColumn('usersPerSubscription', columnlist)) expandColumnsValues(item.usersPerSubscriptions, a);
    if (showColumn('licensesPerSubscription', columnlist)) expandColumnsValues(item.licensesPerSubscription, a);
    if (showColumn('industryCode', columnlist)) a.push(item.code !== null ? item.code + ',' + item.codeName : '');
    if (showColumn('numberOfEmployees', columnlist)) a.push(item.numberOfEmployees! !== null ? item.numberOfEmployees! : '');
    if (showColumn('postalcode', columnlist)) a.push(item.address === null ? '' : item.address?.postalCode!);
    if (showColumn('country', columnlist)) a.push(item.address === null ? '' : item.address?.country!);
    if (showColumn('totalPageViews', columnlist)) a.push(item.totalPageViews === null ? '' : item.totalPageViews!);
    if (showColumn('lockedAt', columnlist)) a.push(item.lockedAt === null ? '' : item.lockedAt!);

    return a;
  });

  data.unshift(headings);

  const sheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, 'Kundeliste');
  XLSX.writeFile(workbook, 'kundeliste.xlsx');
}

function expandColumns(column: ISettings, headings: string[]) {
  if (column.showColumn) {
    if (column.columnName === 'usersPerSubscription' || column.columnName === 'licensesPerSubscription') {
      headings.push('BFS');
      headings.push('BFSB');
      headings.push('BFSP');
      headings.push('BFSF');
      headings.push('BFSU');
      headings.push('BVN');
      headings.push('Prøve');
    } else {
      headings.push(column.name);
    }
  }
}

function expandColumnsValues(licenseOverview: LicenseSubscriptiondata[] | undefined, a: (string | number | null)[]) {
  if (licenseOverview === null) return;

  let possibleSubscriptions = [9, 10, 11, 12, 13, 16, 19];

  possibleSubscriptions.forEach((s) => {
    var item = licenseOverview?.find((c) => c.subscriptionId === s);
    if (item?.numberOfUsers! !== null) a.push(item?.numberOfUsers!);
  });
}

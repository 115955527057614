import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { formatDate } from '../Formatters';
import ByggforskLogo from './../assets/byggforsk.svg?react';
import longhouseLogo from './longhouse.png';
import { Change } from '../interfaces';

interface Props {
  changes: Change[];
}

export function ChangeLog({ changes }: Props) {
  if (!changes) return null;

  return (
    <List>
      {changes.map((x) => (
        <ListItem key={x.id}>
          <ListItemIcon>{x.isExternal ? <ByggforskLogo /> : <img width={24} src={longhouseLogo} alt="Longhouse" />}</ListItemIcon>
          <ListItemText primary={x.description} secondary={`${x.createdBy} ${formatDate(x.createdAt)}`} />
        </ListItem>
      ))}
    </List>
  );
}

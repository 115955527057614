import { gql } from '@apollo/client';

let fragment = gql`
  fragment CommentItem on LicenseCommentModel {
    id
    comment
    createdByName
    createdAt
    lastUpdatedByName
    lastUpdatedAt
    isLocked
  }
`;

export let GET_COMMENTS = gql`
  query ($licenseId: Int!) {
    comments: licenseComments(licenseId: $licenseId) {
      ...CommentItem
    }
  }
  ${fragment}
`;

export const REMOVE_COMMENT = gql`
  mutation RemoveComment($input: LicenseRemoveCommentInput!) {
    licenseRemoveComment(input: $input) {
      id
    }
  }
`;
export const ADD_COMMENT = gql`
  mutation AddComment($input: LicenseAddCommentInput!) {
    licenseAddComment(input: $input) {
      ...CommentItem
    }
  }
  ${fragment}
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($input: LicenseUpdateCommentInput!) {
    licenseUpdateComment(input: $input) {
      ...CommentItem
    }
  }
  ${fragment}
`;

import React from 'react';
import Moment from 'moment';
import { WidgetPanel } from './WidgetPanel';
import { Link } from 'react-router-dom';
import { WidgetTitle } from './WidgetTitle';
import { SummaryFor } from '../renewals/Summary';
import { useState } from 'react';

export function RenewalWidget() {
  const [monthOffset, setMonthOffset] = useState(1); // get statistics for next month as default

  const date = Moment(Date.now()).add(monthOffset, 'months');

  return (
    <WidgetPanel>
      <WidgetTitle
        title="Fornyelser i"
        offset={monthOffset}
        displayYear={true}
        forwardLimit={3}
        yearNav={true}
        stateSetter={(step) => {
          setMonthOffset(monthOffset + step);
        }}
      />
      <SummaryFor date={date} />
      <Link to="/renewal">Se mer</Link>
    </WidgetPanel>
  );
}

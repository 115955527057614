import React, { useState } from 'react';
import { MainContentPanel } from '../../components/MainContentPanel';
import {
  Typography,
  Tabs,
  Tab,
  Badge,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from '@mui/material';
import { MaconomyCustomer } from './MaconomyCustomer';
import { BrregInfo } from './BrregInfo';
import { MaconomySubscription } from './MaconomySubscription';
import { License, LicenseModel, MCustomer } from '../../interfaces';
import { UNLOCK, LOCK } from './LicenseInfo.graphql';
import SearchMaconomyCustomerDialog from './SearchMaconomyCustomerDialog';
import { LockOpen } from '@mui/icons-material';
import { LicenseCard } from './LicenseCard';
import { LockedBanner } from './LockedBanner';
import { DeletedBanner } from './DeletedBanner';
import ConfirmDialog from '../../components/ConfirmDialog';
import { UPDATE_MACONOMY_CUSTOMER_NUMBER, DISCONNECT_MACONOMY } from './Maconomy.graphql';
import { TerminateButton } from './TerminateButton';
import { useMutation } from '@apollo/client';
import { ApiAccess } from './ApiAccess';

enum TabType {
  OUR,
  MACONOMY,
  SUBSCRIPTION,
  BRREG,
  API_ACCESS,
}

export function InfoComposite({ license, bksLicense, suspendValue }: { bksLicense: License; license: LicenseModel; suspendValue: boolean }) {
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.OUR);
  const [hasDiffBrreg, setHasDiffBrreg] = useState(false);
  const [hasDiffMaconomy, setHasDiffMaconomy] = useState(false);
  const [hasDiffMaconomy2, setHasDiffMaconomy2] = useState(false);
  const licenseId = bksLicense.id;

  const handleChange = (_event, value) => {
    setSelectedTab(value);
  };

  const isCompany = !bksLicense.isPersonal;
  const isConnectedToMaconomy = bksLicense.maconomy && bksLicense.maconomy.customerNumber;

  return (
    <MainContentPanel>
      <LockedBanner license={license} bksLicense={bksLicense} />
      <DeletedBanner license={license} bksLicense={bksLicense} />
      <Typography gutterBottom variant="h5">
        Detaljer
      </Typography>

      <Tabs value={selectedTab} onChange={handleChange} orientation="horizontal">
        <Tab value={TabType.OUR} label={<Typography>Våre</Typography>} />
        {isConnectedToMaconomy && (
          <Tab
            value={TabType.MACONOMY}
            label={
              <Badge variant="dot" badgeContent={hasDiffMaconomy ? 1 : 0} color="error">
                <Typography>Stamkort</Typography>
              </Badge>
            }
          />
        )}
        {isConnectedToMaconomy && (
          <Tab
            value={TabType.SUBSCRIPTION}
            label={
              <Badge variant="dot" badgeContent={hasDiffMaconomy2 ? 1 : 0} color="error">
                <Typography>Abonnement</Typography>
              </Badge>
            }
          />
        )}
        {isCompany && (
          <Tab
            value={TabType.BRREG}
            label={
              <Badge variant="dot" badgeContent={hasDiffBrreg ? 1 : 0} color="error">
                <Typography>Brreg</Typography>
              </Badge>
            }
          />
        )}
        <Tab value={TabType.API_ACCESS} label={<Typography>API tilgang</Typography>} />
      </Tabs>
      <Box>
        {selectedTab === TabType.OUR && <LicenseCard display bksLicense={bksLicense} license={license} />}
        {selectedTab === TabType.API_ACCESS && <ApiAccess licenseModelId={license.id} />}
        {selectedTab === TabType.MACONOMY && <MaconomyCustomer display licenseId={licenseId} setHasDiff={setHasDiffMaconomy} />}
        {selectedTab === TabType.SUBSCRIPTION && <MaconomySubscription display licenseId={licenseId} setHasDiff={setHasDiffMaconomy2} />}
        {selectedTab === TabType.BRREG && <BrregInfo display licenseId={licenseId} setHasDiff={setHasDiffBrreg} />}
      </Box>
      <Grid sx={{ marginTop: 2 }} container spacing={1}>
        {bksLicense.isActive && (!bksLicense.maconomy || !bksLicense.maconomy.customerNumber) && <ConnectToMaconomy license={bksLicense} />}
        {bksLicense.isActive && bksLicense.maconomy && bksLicense.maconomy.customerNumber && <DisconnectMaconomy license={bksLicense} />}
        {bksLicense.isActive && <TerminateButton licenseId={licenseId} license={license} suspendValue={suspendValue} />}
        {bksLicense.isActive && <LockButton license={bksLicense} />}
        {bksLicense.isActive && <UnlockButton license={bksLicense} />}
      </Grid>
    </MainContentPanel>
  );
}

interface ConnectProps {
  license: License;
}

function ConnectToMaconomy({ license }: ConnectProps) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [doUpdateMaconomyCustomerNumber, { error: mutError, loading: mutLoading }] = useMutation(UPDATE_MACONOMY_CUSTOMER_NUMBER);

  const handleSearchSubmit = (customer: MCustomer) => {
    setIsSearchOpen(false);

    doUpdateMaconomyCustomerNumber({
      variables: {
        input: {
          licenseId: license.id,
          maconomyCustomerNumber: customer.customerNumber,
          invoiceMonth: customer.invoiceMonth,
          invoiceYear: customer.invoiceYear,
        },
      },
    });
  };

  if (mutError) return <Typography>{mutError.message}</Typography>;
  return (
    <Grid item>
      <Button
        disabled={mutLoading}
        variant="contained"
        color="primary"
        title="Finner kunden i Maconomy og oppretter abonnementsordre"
        onClick={() => setIsSearchOpen(true)}>
        {mutLoading && <CircularProgress size={24} />}
        <span>Koble til Maconomy</span>
      </Button>
      <SearchMaconomyCustomerDialog
        open={isSearchOpen}
        onCancel={() => setIsSearchOpen(false)}
        onSubmit={handleSearchSubmit}
        orgNumber={license.orgNumber}
      />
    </Grid>
  );
}

function DisconnectMaconomy({ license }: { license: License }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [doDisconnectMaconomy, { error, loading }] = useMutation(DISCONNECT_MACONOMY, { variables: { input: { licenseId: license.id } } });

  const disconnect = () => {
    setShowConfirm(false);
    doDisconnectMaconomy();
  };

  if (error) return <Typography>{error.message}</Typography>;
  return (
    <Grid item>
      <Button disabled={loading} variant="contained" color="secondary" title="Koble fra Maconomy" onClick={() => setShowConfirm(true)}>
        {loading && <CircularProgress size={24} />}
        <span>Koble fra Maconomy</span>
      </Button>
      <ConfirmDialog
        open={showConfirm}
        title="Koble fra Maconomy"
        message="Vil du frikoble kunden fra Maconomy? Merk: Kunde og abonnement i Maconomy blir ikke påvirket av dette."
        confirmText="Koble fra"
        deny={() => setShowConfirm(false)}
        confirm={disconnect}
      />
    </Grid>
  );
}

interface LockingProps {
  license: License;
}

function LockButton({ license }: LockingProps) {
  const [reason, setReason] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [lock] = useMutation(LOCK, { variables: { input: { id: license.id, reason } } });

  const close = () => {
    setReason(undefined);
    setOpen(false);
  };

  const commit = () => {
    lock();
    close();
  };

  const catchReturn = (ev) => {
    if (ev.key === 'Enter') {
      commit();
      ev.preventDefault();
    }
  };

  if (license && license.isLocked) return null;
  return (
    <Grid item>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)} startIcon={<LockOpen />}>
        Lås kunde
      </Button>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Lås kunde</DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              label="Årsak"
              fullWidth
              value={reason ? reason : ''}
              onChange={(ev) => setReason(ev.target.value)}
              margin="normal"
              inputProps={{ maxLength: 250 }}
              onKeyPress={catchReturn}
              placeholder="Valgfritt"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Avbryt</Button>
          <Button variant="contained" color="primary" onClick={commit}>
            Lås
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

function UnlockButton({ license }: LockingProps) {
  const [unlock] = useMutation(UNLOCK, { variables: { input: { id: license.id } } });

  if (!license || !license.isLocked) return null;
  return (
    <Grid item>
      <Button variant="contained" color="secondary" onClick={() => unlock()} startIcon={<LockOpen />}>
        Gjenåpne kunde
      </Button>
    </Grid>
  );
}

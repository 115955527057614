import { gql } from '@apollo/client';
import { CustomerItem, CustomerByLicenseItem } from '../../interfaces.graphql';

export const licenseQuery = gql`
  query ($id: Int!) {
    license: license(licenseId: $id) {
      bksLicense {
        id
        orgNumber
        maconomy {
          customerNumber
          subscriptionOrderNumber
          invoiceStartingDate
        }
      }
    }
  }
`;

export const customerSearch = gql`
  query ($name: String!, $orgNumber: String!, $customerNumber: String!) {
    customers: maconomyCustomerSearch(name: $name, orgNumber: $orgNumber, customerNumber: $customerNumber) {
      ...CustomerItem
    }
  }
  ${CustomerItem}
`;

export const updateCustomerNumber = gql`
  mutation UpdateMaconomyCustomerNumber($input: UpdateMaconomyCustomerNumberInput!) {
    updateMaconomyCustomerNumber(input: $input) {
      id
      orgNumber
      maconomy {
        customerNumber
        subscriptionOrderNumber
        invoiceStartingDate
      }
    }
  }
`;

export const GET_MACONOMY_CUSTOMER = gql`
  query maconomyCustomer($customerNumber: String!) {
    maconomyCustomer(customerNumber: $customerNumber) {
      ...CustomerItem
    }
  }
  ${CustomerItem}
`;

export const GET_MACONOMY_CUSTOMER_BY_LICENSE = gql`
  query maconomyCustomerByLicense($licenseId: Int!) {
    maconomyCustomerByLicense(licenseId: $licenseId) {
      ...CustomerByLicenseItem
    }
  }
  ${CustomerByLicenseItem}
`;

export const GET_MACONOMY_ORDER = gql`
  query maconomySubscription($licenseId: Int!) {
    maconomySubscription(licenseId: $licenseId) {
      companyName
      orderNumber
      invoiceStartingDate
      attention
      address {
        id
        address1
        address2
        city
        postalCode
        country
      }
      hasSameDataAsUs
      lines {
        subscriptionType
        subscriptionNumber
        numberOf
        amount
        comment
        deliveryStartingDate
        invoiceStartingDate
        dateOrdered
        invoicedUntil
        nextInvoiceDate
        discountPercentage
        lineNumber
        deliveryEndingDate
        invoiceEndingDate
        nonInvoiceable
      }
    }
  }
`;

export const UPDATE_MACONOMY_CUSTOMER_NUMBER = gql`
  mutation UpdateMaconomyCustomerNumber($input: UpdateMaconomyCustomerNumberInput!) {
    updateMaconomyCustomerNumber(input: $input) {
      id
      maconomy {
        customerNumber
      }
    }
  }
`;

export const DISCONNECT_MACONOMY = gql`
  mutation DisconnectMaconomy($input: DisconnectMaconomyInput!) {
    disconnectMaconomy(input: $input) {
      id
      maconomy {
        customerNumber
        subscriptionOrderNumber
        invoiceStartingDate
      }
    }
  }
`;

export const HAS_MISSING_ORDERS = gql`
  query ($licenseId: Int!) {
    hasMissingOrders(licenseId: $licenseId)
  }
`;

import { gql } from '@apollo/client';

const ModelItem = gql`
  fragment GuidePriceModelItem on GuidePriceModel {
    id
    price
    pendingPriceChange {
      id
      timeOfChange
      newPrice
    }
  }
`;

export let GET_PRICE = gql`
  query {
    pdf: getPdfPrice {
      ...GuidePriceModelItem
    }
  }
  ${ModelItem}
`;

export const UPDATE_PRICE = gql`
  mutation ChangePdfPrice($input: ChangePdfPriceInput!) {
    changePdfPrice(input: $input) {
      ...GuidePriceModelItem
    }
  }
  ${ModelItem}
`;

export const CANCEL_PRICE_UPDATE = gql`
  mutation CancelPdfPriceChange {
    cancelPdfPriceChange {
      ...GuidePriceModelItem
    }
  }
  ${ModelItem}
`;

const SingleDocumentAccessModelItem = gql`
  fragment SingleDocumentAccessModelItem on SingleDocumentAccessQueryModel {
    id
    docId
    docName
    title
    validFrom
    validTo
    userId
    changeModelDocIds
    orderNumber
  }
`;

export const GET_ACCESSES = gql`
  query ($licenseId: Int!) {
    accesses: singleDocumentAccess(licenseId: $licenseId) {
      ...SingleDocumentAccessModelItem
    }
  }
  ${SingleDocumentAccessModelItem}
`;

export const GET_ACCESSES_FOR_USER = gql`
  query ($userId: Int!) {
    accesses: singleDocumentAccessForUser(userId: $userId) {
      ...SingleDocumentAccessModelItem
    }
  }
  ${SingleDocumentAccessModelItem}
`;

export const STOP_ACCESSES = gql`
  mutation ($input: StopSingleDocumentAccessInput!) {
    access: stopSingleDocumentAccess(input: $input) {
      ...SingleDocumentAccessModelItem
    }
  }
  ${SingleDocumentAccessModelItem}
`;

import { Typography, Grid, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { WidgetPanel } from './WidgetPanel';
import makeStyles from '@mui/styles/makeStyles';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { numberFormatted } from '../Formatters';

const GET_USER_COUNTS = gql`
  query {
    counts: getUserCounts {
      bfsUserData {
        subscription
        name
        licenseCount
        userCount
      }
      bvnUserData {
        subscription
        name
        licenseCount
        userCount
      }
      studentData {
        subscription
        name
        licenseCount
        userCount
      }
      bfsLicensesTotal
      bfsUsersTotal
      licensesTotal
      usersTotal
      studentsTotal
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  total: {
    fontWeight: 'bold',
  },
  panel: {},
  divider: {
    marginTop: 5,
  },
  indent1: {
    paddingLeft: 8,
  },
  indent2: {
    paddingLeft: 16,
  },
}));

export function UserCounts() {
  const classes = useStyles();
  const { data } = useQuery(GET_USER_COUNTS);

  return (
    <div className={classes.panel}>
      <WidgetPanel>
        <Grid container>
          <Grid item>
            <Typography variant="h5" gutterBottom>
              <span>Totalt antall kunder og brukere</span>
            </Typography>
          </Grid>
        </Grid>

        {!data && <LoadingSpinner />}
        {data && (
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell>Abonnement</TableCell>
                <TableCell align="right">Kunder</TableCell>
                <TableCell align="right">Brukere</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Bfsprodukter totalt:</TableCell>
                <TableCell align="right">{numberFormatted(data.counts.bfsLicensesTotal)}</TableCell>
                <TableCell align="right">{numberFormatted(data.counts.bfsUsersTotal)}</TableCell>
              </TableRow>
              {data.counts.bfsUserData.map((r, i) => (
                <TableRow key={i}>
                  <TableCell className={r.subscription === 9 ? classes.indent1 : classes.indent2}>{r.name}:</TableCell>
                  <TableCell align="right">{numberFormatted(r.licenseCount)}</TableCell>
                  <TableCell align="right">{numberFormatted(r.userCount)}</TableCell>
                </TableRow>
              ))}
              {data.counts.bvnUserData.map((r, i) => (
                <TableRow key={i}>
                  <TableCell>{r.name}:</TableCell>
                  <TableCell align="right">{numberFormatted(r.licenseCount)}</TableCell>
                  <TableCell align="right">{numberFormatted(r.userCount)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <span className={classes.total}>Totalt:</span>
                </TableCell>
                <TableCell align="right">{numberFormatted(data.counts.licensesTotal)}</TableCell>
                <TableCell align="right">{numberFormatted(data.counts.usersTotal)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3}>
                  <span className={classes.total}>Studenter</span>
                </TableCell>
              </TableRow>
              {data.counts.studentData.map((r, i) => (
                <TableRow key={i}>
                  <TableCell>{r.name}:</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{numberFormatted(r.userCount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </WidgetPanel>
    </div>
  );
}

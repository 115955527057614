import React, { useState } from 'react';
import { Typography, IconButton, Chip } from '@mui/material';
import { Edit, Face } from '@mui/icons-material';
import { MainContentPanel } from '../../components/MainContentPanel';
import { UserCard } from '../../users/details/UserCard';
import { SetEmailDialog } from '../../components/SetEmailDialog';
import { GET_CONTACT, UPDATE_CONTACT, CANCEL_UPDATE_CONTACT } from './ContactPerson.graphql';
import LoadingSpinner from '../../components/LoadingSpinner';
import { LicenseModel } from '../../interfaces';
import { useMutation, useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
}

interface ContactData {
  licenseContact: {
    license: LicenseModel;
    bksLicense: any;
  };
}
export function ContactPerson({ licenseId }: Props) {
  const [openChangeDialog, setOpenChangeDialog] = useState(false);
  const [cancelUpdate] = useMutation(CANCEL_UPDATE_CONTACT);
  const [updateContact] = useMutation(UPDATE_CONTACT);
  const { loading, data } = useQuery<ContactData>(GET_CONTACT, { variables: { id: licenseId } });

  const Title = (props) => (
    <Typography gutterBottom variant="h5">
      Administrator
      {props.children}
    </Typography>
  );
  if (loading || !data)
    return (
      <MainContentPanel>
        <Title />
        <LoadingSpinner />
      </MainContentPanel>
    );

  const {
    licenseContact: { license, bksLicense },
  } = data;
  const hasPendingChange = license && license.pendingContact;
  return (
    <MainContentPanel>
      <Title>
        {!hasPendingChange && (
          <IconButton title="Bytt administrator" onClick={() => setOpenChangeDialog(true)} size="large">
            <Edit />
          </IconButton>
        )}
      </Title>
      {hasPendingChange && (
        <Chip
          icon={<Face />}
          label="I påvente av bytte"
          onDelete={() => cancelUpdate({ variables: { input: { id: licenseId, userId: license.pendingContact } } })}
        />
      )}
      <UserCard user={bksLicense.contact} />
      <SetEmailDialog
        open={openChangeDialog}
        title="Endre administrator"
        onClose={() => setOpenChangeDialog(false)}
        update={(email: string) => updateContact({ variables: { input: { id: licenseId, email: email } } })}
      />
    </MainContentPanel>
  );
}

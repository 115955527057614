import { TextField } from '@mui/material';
import { useState } from 'react';
import { AdfsSetting } from '../../interfaces';
import IsDomainValid from './DomainUtils';
import { getDomainAsArrayFromString } from './DomainUtils';
interface Prop {
  callbackValue: (name: string) => void;
  callbackError: (name: boolean) => void;
  result: AdfsSetting[] | undefined;
  id: string;
  domain: string[];
}
export default function EmailDomain(prop: Prop) {
  const { callbackValue: sendbackDomain, result, callbackError: sendbackError, id, domain } = prop;
  const [errorText, setErrorText] = useState('');
  const [emailDomain, setEmailDomain] = useState(domain);
  const handleEmailChange = (email: any) => {
    clearError();
    setEmailDomain(email);
  };
  const clearError = () => {
    setErrorText('');
    sendbackError(false);
  };
  const setError = (errMessage: string) => {
    setErrorText(errMessage);
    sendbackError(true);
  };
  const handleEmailBlur = (email: string) => {
    checkIfDuplicate(email);
    checkIfCorrectFormat(email);
  };
  const checkIfCorrectFormat = (email: string) => {
    IsDomainValid(email) ? sendbackDomain(email) : setError('invalid format');
  };
  const checkIfDuplicate = (email: string) => {
    let newDomain = getDomainAsArrayFromString(email);
    if (isDuplicateDomain(result, newDomain)) {
      setError('duplicate domain');
    }
  };
  const isDuplicateDomain = (existingDomains: any, newDomains: string[]) => {
    let result = false;
    for (const newDomain in newDomains) {
      for (const existingDomain of existingDomains) {
        if (id) {
          result = existingDomain.emailDomains.includes(newDomains[newDomain]) && existingDomain.id !== id ? true : false;
        } else {
          result = existingDomain.emailDomains.includes(newDomains[newDomain]) ? true : false;
        }
        if (result) {
          break;
        }
      }
      if (result) {
        break;
      }
    }
    return result;
  };

  return (
    <>
      <TextField
        label="Email Domains (eg: sintef.no,ubf.oslo.kommune.no)"
        error={errorText.length === 0 ? false : true}
        helperText={errorText}
        fullWidth
        value={emailDomain}
        type="text"
        onChange={(event) => handleEmailChange(event.target.value)}
        onBlur={(event) => handleEmailBlur(event.target.value)}
        margin="normal"
        required
      />
    </>
  );
}

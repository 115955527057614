import * as React from 'react';
import { DocViewsPerCompanyUserModel, PerDocViewsModelProps } from '../../interfaces';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENT_VIEWS_PER_COMPANY_USER } from '../reports.graphql';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, TextField, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GeneralExcelExport, GeneralExcelExportProps } from '../brreg';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const useStyles = makeStyles({
  tableHead: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '20px',
    color: '#555555',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    width: '80%',
  },
  antallHead: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '20px',
    color: '#555555',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    width: '20%',
  },

  firstName: {
    paddingRight: '8px',
    fontSize: '16px',
    color: '#287891',
    fontFamily: 'Arial',
  },
  lastName: {
    fontSize: '16px',
    color: '#287891',
    fontFamily: 'Arial',
  },
  docCell: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  searchField: {
    marginBottom: '16px',
    display: 'flex',
  },
  searchInput: {
    flexGrow: 1,
    marginLeft: '16px',
  },
});

function ViewsPerUser(props: PerDocViewsModelProps) {
  const { orgNumber, fromDate, toDate, productType } = props;
  const classes = useStyles();

  const { loading, error, data } = useQuery<{ docViewsPerCompanyUser: DocViewsPerCompanyUserModel[] }>(GET_DOCUMENT_VIEWS_PER_COMPANY_USER, {
    variables: {
      orgNumber: orgNumber,
      fromDate: fromDate,
      toDate: toDate,
      productType: productType,
    },
  });
  const [sortColumn, setSortColumn] = React.useState('navn');
  const [sortOrder, setSortOrder] = React.useState('asc');
  const [searchQuery, setSearchQuery] = React.useState('');

  if (loading) return <CircularProgress />;
  if (error) return <p>Error :(</p>;

  const sortFunctions = {
    navn: (a, b) => a.firstName.localeCompare(b.firstName),
    antall: (a, b) => a.views - b.views,
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sort order
      setSortOrder(newSortOrder);
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  let filteredData = data?.docViewsPerCompanyUser;

  // sort item by name and views
  if (filteredData) {
    filteredData = filteredData.slice();
    const sortFunction = sortFunctions[sortColumn];
    if (sortFunction) {
      filteredData.sort((a, b) => {
        const compareResult = sortFunction(a, b);
        return sortOrder === 'asc' ? compareResult : -compareResult;
      });
    }
  }

  // filter data on search keyword
  if (filteredData) {
    filteredData = filteredData.filter((docView: DocViewsPerCompanyUserModel) => {
      const query = searchQuery.toLowerCase();
      return docView.firstName.toLowerCase().includes(query) || docView.views.toString().includes(query);
    });
  }

  const arrHeading: string[] = ['Navn', 'Antall'];
  const dataSet = filteredData && filteredData.map((x) => [x.firstName + ' ' + x.lastName, x.views]);
  const workBook = 'SIDEVISNING_ANTALL_PER_BRUKER';
  const fileName = 'sidevisning_per_bruker.xlsx';
  const propsExcel: GeneralExcelExportProps = {
    dataSet: JSON.stringify(dataSet),
    headings: JSON.stringify(arrHeading),
    workBookName: workBook,
    fileName: fileName,
  };

  const handleUserClick = (userId) => {
    const url = `https://salg.byggforsk.no/user/${userId}`;
    window.open(url, '_blank');
  };

  return (
    <Box p={2} border={1} borderColor="grey.300" borderRadius={4} m={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.searchField}>
        <TextField label="Søk" value={searchQuery} onChange={handleSearch} className={classes.searchInput} />
        {filteredData && <GeneralExcelExport {...propsExcel} />}
      </Box>

      <Table aria-label="views per document table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} onClick={() => handleSort('navn')}>
              <Typography variant="subtitle1" component="div">
                <Link underline="none" color={sortColumn === 'navn' ? 'textPrimary' : 'inherit'}>
                  Navn
                  {sortColumn === 'navn' && (sortOrder === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
                </Link>
              </Typography>
            </TableCell>
            <TableCell className={classes.antallHead} onClick={() => handleSort('antall')}>
              <Typography variant="subtitle1" component="div">
                <Link underline="none" color={sortColumn === 'antall' ? 'textPrimary' : 'inherit'}>
                  Antall
                  {sortColumn === 'antall' && (sortOrder === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
                </Link>
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.map((docView: DocViewsPerCompanyUserModel) => (
            <TableRow key={docView.userId}>
              <TableCell className={classes.docCell} onClick={() => handleUserClick(docView.userId)}>
                <Typography className={classes.firstName}>{docView.firstName}</Typography>
                <Typography className={classes.lastName}>{docView.lastName}</Typography>
              </TableCell>
              <TableCell>{docView.views}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default ViewsPerUser;

import { DataProxy } from '@apollo/client/cache';
import { Task } from '../../interfaces';
import { gql } from '@apollo/client';

const fragment = gql`
  fragment TaskItem on TaskModel {
    id
    bksLicenseId
    licenseName
    bksLicenseId
    typeName
    description
    searchableDescription
    markup
    comment
    isCreatedManually
    createdAt
    createdByName
    lastUpdatedAt
    lastUpdatedByName
    completedAt
    completedByName
  }
`;

export const allTasksQuery = gql`
  query ($showCompleted: Boolean!) {
    tasks: allTasks(showCompleted: $showCompleted) {
      ...TaskItem
    }
  }
  ${fragment}
`;

export const tasksForLicenseQuery = gql`
  query ($licenseId: Int!, $showCompleted: Boolean!) {
    tasks: tasksForLicense(bksLicenseId: $licenseId, showCompleted: $showCompleted) {
      ...TaskItem
    }
  }
  ${fragment}
`;

export const createTask = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...TaskItem
    }
  }
  ${fragment}
`;

export const updateTask = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      lastUpdatedAt
      lastUpdatedByName
      completedAt
      completedByName
      comment
    }
  }
`;

export const deleteTask = gql`
  mutation DeleteTask($input: DeleteTaskInput!) {
    deleteTask(input: $input)
  }
`;

export const incompleteTaskCountQuery = gql`
  query {
    number: incompleteTaskCount
  }
`;

export const GET_LICENSES_FOR_TASK = gql`
  query {
    licenses: getLicensesForTasks {
      id
      companyName
    }
  }
`;

export const updateQueryCacheOnAdd = (cache: DataProxy, taskModel: Task, query: any, variables?: any) => {
  try {
    const result: { tasks: Task[] } | null = cache.readQuery({ query, variables });
    if (result === null) return;

    const tasks = result.tasks;
    if (tasks.some((t) => t.id === taskModel.id)) return;

    cache.writeQuery({
      query,
      variables,
      data: { tasks: [...tasks, taskModel] },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateQueryCacheOnDelete = (cache: DataProxy, taskId: string, query: any, variables?: any) => {
  try {
    const result: { tasks: Task[] } | null = cache.readQuery({ query, variables });
    if (result === null) return;

    const tasks = result.tasks.filter((t) => t.id !== taskId);
    cache.writeQuery({
      query,
      variables,
      data: { tasks },
    });
  } catch (error) {
    console.error(error);
  }
};

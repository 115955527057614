import { TableCell, TableBody, Table, TableRow } from '@mui/material';
import { WidgetPanel } from './WidgetPanel';
import { WidgetTitle } from './WidgetTitle';
import makeStyles from '@mui/styles/makeStyles';
import LoadingSpinner from '../components/LoadingSpinner';
import { gql, useQuery } from '@apollo/client';
import { numberAsKr } from '../Formatters';
import { useState } from 'react';

const GET_SALES_SUMMARY = gql`
  query ($monthOffset: Int!) {
    salesSummary: getSalesSummary(monthOffset: $monthOffset) {
      newSales
      totalSales
      addedSales
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  total: {
    fontWeight: 'bold',
  },
  panel: {},
  divider: {
    marginTop: 5,
  },
  btn: {
    minWidth: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export function SalesSummary() {
  const classes = useStyles();

  const [monthOffset, setMonthOffset] = useState(0); // get statistics for current month

  const { data } = useQuery(GET_SALES_SUMMARY, { variables: { monthOffset: monthOffset } });

  return (
    <div className={classes.panel}>
      <WidgetPanel>
        <WidgetTitle
          title="Salgstall for"
          offset={monthOffset}
          forwardLimit={1}
          yearNav={true}
          stateSetter={(step) => {
            setMonthOffset(monthOffset + step);
          }}
        />

        {!data && <LoadingSpinner />}
        {data && (
          <Table padding="none">
            <TableBody>
              <TableRow>
                <TableCell>Nysalg: </TableCell>
                <TableCell align="right">{numberAsKr(data.salesSummary.newSales)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mersalg: </TableCell>
                <TableCell align="right">{numberAsKr(data.salesSummary.addedSales)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <span className={classes.total}>Totalt:</span>
                </TableCell>
                <TableCell align="right">
                  <span className={classes.total}>{numberAsKr(data.salesSummary.totalSales)}</span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}

        {/* <LinkToDetails>Se mer</LinkToDetails> */}
      </WidgetPanel>
    </div>
  );
}

import { gql } from '@apollo/client';

export const GET_CHURN = gql`
  query ($fromDate: DateTimeOffset!, $toDate: DateTimeOffset, $invoiceMonth: Int, $type: ChurnType, $actions: ChurnAction) {
    model: getChurn(fromDate: $fromDate, toDate: $toDate, invoiceMonth: $invoiceMonth, type: $type, actions: $actions) {
      bfsAll {
        timestamp
        value
      }
      bfs {
        timestamp
        value
      }
      bfsp {
        timestamp
        value
      }
      bfsb {
        timestamp
        value
      }
      bfsf {
        timestamp
        value
      }
      bfsu {
        timestamp
        value
      }
      bvn {
        timestamp
        value
      }
    }
  }
`;

export const GET_TERMINATION = gql`
  query ($fromDate: DateTimeOffset!, $toDate: DateTimeOffset) {
    model: getTermination(fromDate: $fromDate, toDate: $toDate) {
      licenseUserName
      licenseSubscriptions
      terminationRequestedDate
      terminationReason
      id
    }
  }
`;

export const GET_GUIDES = gql`
  query {
    guides: allGuides {
      docId
      docName
      title
    }
  }
`;

export const GET_USER_OCCUPATION = gql`
  query {
    userOccupation: getUserByOccupation {
      id
      occupation
      company
      email
    }
  }
`;

export const GET_BUSINESS_CODE = gql`
  query ($filterOption: String) {
    businesses: getBusinessCode(filterOption: $filterOption) {
      code
      companyCount
      description
      licenseCount
      userCount
    }
  }
`;

export const GET_LICENSE_BY_GUIDE = gql`
  query ($guideId: Int!, $fromDate: DateTimeOffset!, $toDate: DateTimeOffset) {
    customers: getLicenseByGuide(guideId: $guideId, fromDate: $fromDate, toDate: $toDate) {
      customerId
      name
      count
      email
      phoneNumber
      downloads {
        count
        type
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query ($code: String, $filterOption: String) {
    employees: getEmployeeByBusinessCode(code: $code, filterOption: $filterOption) {
      key
      companyCount
      licenseCount
      userCount
    }
  }
`;

export const GET_EMPLOYEE_ALL = gql`
  query ($noCodeFilterOption: String) {
    employees: getEmployeeAll(filterOption: $noCodeFilterOption) {
      key
      companyCount
      licenseCount
    }
  }
`;

export const GET_INCORP_YEAR_ALL = gql`
  query ($noCodeFilterOption: String) {
    incorporationscomp: getIncorporationYearAll(filterOption: $noCodeFilterOption) {
      key
      companyCount
      licenseCount
    }
  }
`;

export const GET_COMPANIES = gql`
  query {
    companyOverview: getCompanies {
      orgNumber
      companyName
      brreg
      licenseCount
      userCount
    }
  }
`;

export const GET_DOCUMENT_VIEWS_PER_ORG = gql`
  query GetDocumentViewsPerOrg($orgNumber: String!, $fromDate: String, $toDate: String) {
    docViews: getDocViewsPerCompany(orgNumber: $orgNumber, fromDate: $fromDate, toDate: $toDate) {
      docNumber
      title
      views
      seriesId
      sourceId
      docId
    }
  }
`;

export const GET_DOCUMENT_VIEWS_PER_COMPANY_USER = gql`
  query GetDocViewsPerCompanyUser($orgNumber: String!, $fromDate: String, $toDate: String, $productType: String) {
    docViewsPerCompanyUser: getDocViewsPerCompanyUser(orgNumber: $orgNumber, fromDate: $fromDate, toDate: $toDate, productType: $productType) {
      userId
      firstName
      lastName
      views
      seriesId
      sourceId
    }
  }
`;

export const GET_DOWNLOADS_PER_COMPANY_USER = gql`
  query GetDownloadsPerCompanyUser($orgNumber: String!, $fromDate: String, $toDate: String, $productType: String) {
    downloadsPerCompanyUser: getDownloadsPerCompanyUser(orgNumber: $orgNumber, fromDate: $fromDate, toDate: $toDate, productType: $productType) {
      userId
      firstName
      lastName
      downloads
      seriesId
      sourceId
    }
  }
`;

export const GET_COMPANY = gql`
  query ($employeeRange: String, $code: String, $filterOption: String) {
    companies: getCompanyByCodeAndRange(employeeRange: $employeeRange, code: $code, filterOption: $filterOption) {
      companyName
      orgNumber
      maconomyCustomerNumber
      admin
      createDate
      code
      codeDescription
      userCount
    }
  }
`;

export const GET_COMPANY_ALL = gql`
  query ($employeeRange: String, $noCodeFilterOption: String) {
    companies: getCompanyByEmployeeRange(employeeRange: $employeeRange, filterOption: $noCodeFilterOption) {
      companyName
      orgNumber
      maconomyCustomerNumber
      admin
      createDate
      code
      codeDescription
    }
  }
`;

export const GET_INCORP_YEAR_DETAIL_ALL = gql`
  query ($incorporationYearRange: String, $noCodeFilterOption: String) {
    inccompanies: getCompanyByIncorporationYearRange(incorporationYearRange: $incorporationYearRange, filterOption: $noCodeFilterOption) {
      companyName
      orgNumber
      maconomyCustomerNumber
      admin
      incorporationDate
      code
      codeDescription
    }
  }
`;

export const GET_USELOG_BY_LICENSE = gql`
  query ($licenseId: Int!, $guideId: Int!, $fromDate: DateTimeOffset!, $toDate: DateTimeOffset, $email: String) {
    uselogs: getUseLogByLicense(licenseId: $licenseId, guideId: $guideId, fromDate: $fromDate, toDate: $toDate, email: $email) {
      numberOfVisit
      userId
      firstName
      lastName
      email
      medium
      displayName
    }
  }
`;

export const GET_PENDING_TERMINATION = gql`
  query ($fromDate: DateTimeOffset!, $toDate: DateTimeOffset) {
    model: getPendingTermination(fromDate: $fromDate, toDate: $toDate) {
      licenseUserName
      licenseSubscriptions
      terminationRequestedDate
      terminationReason
      id
    }
  }
`;

export const GET_LICENSES_WITH_HIGH_USAGE = gql`
  query ($monthOffset: Int!, $take: Int!) {
    licenses: getLicensesWithHighUsage(monthOffset: $monthOffset, take: $take) {
      licenseId
      companyName
      orgNumber
      views
    }
  }
`;

export const GET_USERS_WITH_HIGH_USAGE = gql`
  query ($monthOffset: Int!, $take: Int!) {
    users: getUsersWithHighUsage(monthOffset: $monthOffset, take: $take) {
      userId
      name
      licenseId
      companyName
      views
    }
  }
`;

import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Usage } from '../Usage';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    marginBottom: '20px',
  },
});

interface DocumentOverviewProps {
  onBackClick: () => void;
}

export default function DocumentOverview(props: DocumentOverviewProps) {
  const { onBackClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <IconButton onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h5" color="black">
            Anvisning liste
          </Typography>
        </Grid>
      </Grid>
      <Usage />
    </div>
  );
}

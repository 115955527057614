import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { MainContentPanel } from '../components/MainContentPanel';
import { OrderSearchableTable } from './overview/OrderSearchableTable';
import LoadingSpinner from '../components/LoadingSpinner';
import { useConfig } from '../components/Configuration';
import { GET_ORDERS_FOR_LICENSE } from './overview/Orders.graphql';
import { useQuery } from '@apollo/client';

interface Props {
  licenseId: number;
}

export function Orders({ licenseId }: Props) {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const [showIncomplete, setShowIncomplete] = useState(false);
  const { data, loading } = useQuery(GET_ORDERS_FOR_LICENSE, { variables: { id: licenseId, showIncomplete }, pollInterval: pollIntervall });
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        Ordre
      </Typography>
      {loading && <LoadingSpinner />}
      {data && <OrderSearchableTable orders={data.orders} showIncomplete={showIncomplete} setShowIncomplete={setShowIncomplete} />}
    </MainContentPanel>
  );
}

import { gql } from '@apollo/client';

const fragment = gql`
  fragment UserCommentItem on UserComment {
    id
    comment
    createdByName
    createdAt
    lastUpdatedByName
    lastUpdatedAt
    isLocked
  }
`;

export const GET_COMMENTS = gql`
  query ($userId: Int!) {
    comments: userComments(id: $userId) {
      ...UserCommentItem
    }
  }
  ${fragment}
`;

export const REMOVE_COMMENT = gql`
  mutation ($input: UserRemoveCommentInput!) {
    userRemoveComment(input: $input) {
      id
    }
  }
`;
export const ADD_COMMENT = gql`
  mutation ($input: UserAddCommentInput!) {
    userAddComment(input: $input) {
      ...UserCommentItem
    }
  }
  ${fragment}
`;

export const UPDATE_COMMENT = gql`
  mutation ($input: UserUpdateCommentInput!) {
    userUpdateComment(input: $input) {
      ...UserCommentItem
    }
  }
  ${fragment}
`;

import React, { FC, useState } from 'react';
import { Typography, IconButton, FormControlLabel, Switch, Badge } from '@mui/material';
import TaskTable from './TaskTable';
import { MainContentPanel } from '../../components/MainContentPanel';
import { Add } from '@mui/icons-material';
import {
  allTasksQuery,
  tasksForLicenseQuery,
  createTask,
  updateQueryCacheOnAdd,
  deleteTask,
  updateQueryCacheOnDelete,
  updateTask,
  incompleteTaskCountQuery,
} from './Tasks.graphql';
import CreateTaskDialog from './CreateTaskDialog';
import { Task } from '../../interfaces';
import { useQuery, useMutation } from '@apollo/client';
import LoadingSpinner from '../../components/LoadingSpinner';
import { SearchField } from '../../components/SearchField';
import Fuse from 'fuse.js';
import { useConfig } from '../../components/Configuration';

interface Props {
  licenseId?: number;
  descriptionWidth?: number;
}

export default function Overview({ licenseId, descriptionWidth }: Props) {
  const [open, setOpen] = useState(false);
  const config = useConfig();
  const [showComplete, setShowComplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [doCreateTask] = useMutation(createTask);
  const [doUpdateTask] = useMutation(updateTask);
  const [doDeleteTask] = useMutation(deleteTask);
  const query = licenseId ? tasksForLicenseQuery : allTasksQuery;
  const variables = licenseId ? { licenseId, showCompleted: showComplete } : { showCompleted: showComplete };
  const pollIntervall = config ? config.defaultPollingInterval : 0;

  const { data, loading } = useQuery<{ tasks: Task[] }>(query, { variables, pollInterval: pollIntervall });

  const submit = ({ bksLicenseId, description }) => {
    doCreateTask({
      variables: { input: { bksLicenseId, description, isCreatedManually: true } },
      update: (cache, { data: createTaskData }) => {
        if (createTaskData) updateQueryCacheOnAdd(cache, createTaskData.createTask, query, variables);
      },
    });
    setOpen(false);
  };

  const doDoDeleteTask = (task: Task) => {
    doDeleteTask({
      variables: { input: { id: task.id } },
      update: (cache, { data: deleteTaskData }) => {
        if (deleteTaskData) updateQueryCacheOnDelete(cache, deleteTaskData.deleteTask, query, variables);
      },
    });
  };

  const filter = (tasks: Task[]) => {
    const preFiltered = showComplete ? tasks : tasks.filter((t) => !t.completedAt);

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['licenseName', 'searchableDescription', 'typeName', 'lastUpdatedByName'],
    };

    if (!searchTerm) {
      return preFiltered;
    } else {
      var fuse = new Fuse(preFiltered, options);
      var filtered = fuse.search(searchTerm);
      return filtered;
    }
  };

  if (!data || loading) return <LoadingSpinner />;
  return (
    <MainContentPanel>
      <Typography variant="h5">
        <span>Oppgaver</span>
        <IconButton onClick={() => setOpen(true)} size="large">
          <Add />
        </IconButton>
      </Typography>
      {!licenseId && <SearchField filter={setSearchTerm} />}
      <Filter showComplete={showComplete} handleShowComplete={() => setShowComplete(!showComplete)} />

      <TaskTable
        tasks={filter(data.tasks)}
        licenseId={licenseId}
        updateTask={doUpdateTask}
        deleteTask={doDoDeleteTask}
        descriptionWidth={descriptionWidth}
      />
      <CreateTaskDialog open={open} licenseId={licenseId} onCancel={() => setOpen(false)} onSubmit={(data) => submit(data)} />
    </MainContentPanel>
  );
}

const Filter = ({ showComplete, handleShowComplete }) => (
  <FormControlLabel control={<Switch name="showComplete" onChange={handleShowComplete} checked={showComplete} />} label="Vis fullførte" />
);

export const IncompleteTasksBadgeContent: FC<{ children: React.ReactNode }> = ({ children }) => {
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollingInterval : 0;
  const { loading, error, data } = useQuery<{ number: number }>(incompleteTaskCountQuery, { pollInterval: pollIntervall });
  if (!loading && !error && data)
    return (
      <Badge badgeContent={data.number} color="error">
        {children}
      </Badge>
    );

  return children;
};

import React, { FC } from 'react';
import { Divider, List, Hidden, Drawer, MenuItem, ListItemIcon, ListItemText, useTheme, Badge } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { RouteDef } from './Shell';
import { makeStyles } from '@mui/styles';

interface SidebarProps {
  routes: RouteDef[];
  mobileOpen: boolean;
  handleDrawerToggle(): void;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
    height: '100vh',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'relative',
    },
  },
  menuLink: {
    color: 'black',
    textDecoration: 'none',
  },
  logo: {
    width: 60,
    height: 60,
    marginLeft: 20,
  },
}));

const ListItemLink: FC<{ route: RouteDef }> = ({ route }) => {
  const classes = useStyles();
  const history = useHistory();
  const { path, title, icon } = route;
  let click = () => {
    history.push(path);
  };
  let renderLink = () => {
    return (
      <Link className={classes.menuLink} to={path}>
        {title}
      </Link>
    );
  };

  let currentPath = history.location.pathname;
  return (
    <MenuItem onClick={click} selected={path === '/' ? currentPath === path : currentPath.startsWith(path)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={renderLink()} />
    </MenuItem>
  );
};

export const Sidebar: FC<SidebarProps> = ({ routes, mobileOpen, handleDrawerToggle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const drawer = (
    <div>
      <div className={classes.drawerHeader} style={{ backgroundColor: '#FAFAFA' }}>
        <Link to={'/'}>
          <img src="/longhouse.svg" className={classes.logo} alt=""></img>
        </Link>
      </div>
      <Divider />
      <List>
        {routes
          .filter((x) => x.title)
          .map((x, index) => (
            <ListItemLink key={index} route={x} />
          ))}
      </List>
    </div>
  );
  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}>
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

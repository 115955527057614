import { gql } from '@apollo/client';
import { UserItem } from '../../interfaces.graphql';

export const OrderItem = gql`
  fragment OrderItem on OrderQueryModel {
    id
    orderNumber
    invoiceNumber
    creditInvoiceNumber
    description
    amount
    paymentMode
    registrationEmail
    user {
      ...UserItem
    }
    license {
      id
      companyName
    }
    type
    status
    startedAt
    invoiceContentId
    creditInvoiceContentId
    errorMessage
    isInvoiceable
  }
  ${UserItem}
`;

export const GET_ORDERS = gql`
  query ($showIncomplete: Boolean!, $limit: Int) {
    orders: allOrders(showIncomplete: $showIncomplete, limit: $limit) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

export const GET_ORDERS_FOR_LICENSE = gql`
  query ($id: Int!, $showIncomplete: Boolean!) {
    orders: ordersByLicenseId(licenseId: $id, showIncomplete: $showIncomplete) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

export const GET_COMPLETED_ORDERS_WITH_DISCOUNT = gql`
  query ($id: ID!) {
    orders: completedOrdersWithDiscount(discountId: $id) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

export const CREDIT_ORDER = gql`
  mutation CreditOrder($input: CreditOrderInput!) {
    creditOrder(input: $input) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

import React from 'react';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import { MainContentPanel } from '../../components/MainContentPanel';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';

export function Folders() {
  return (
    <MainContentPanel>
      <Typography gutterBottom variant="h5">
        <span>Rabatter</span>
        <Link to="/discount/new">
          <IconButton size="large">
            <Add />
          </IconButton>
        </Link>
      </Typography>
      <FolderTable />
    </MainContentPanel>
  );
}

function FolderTable() {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Annen info</TableCell>
            <TableCell style={{ width: 355 }} />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Link to={'/discounts/1'}> Alle</Link>
            </TableCell>
            <TableCell>Alle rabatter</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link to={'/discounts/2'}>Studenter</Link>
            </TableCell>
            <TableCell>Alle studenter med rabatt</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link to={'/discounts/3'}>Firmaer</Link>
            </TableCell>
            <TableCell>Andre kunder med rabatt</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link to={'/discounts/4'}>Ip-adresse</Link>
            </TableCell>
            <TableCell>Alle med Ip-adresse eller intranett</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link to={'/discounts/5'}>SSO/AD</Link>
            </TableCell>
            <TableCell>Alle med AFDS innlogging</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link to={'/discounts/6'}>Uten kunder</Link>
            </TableCell>
            <TableCell>Rabatter uten tilhørende kunder</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

import XLSX from 'xlsx';
import { Button } from '@mui/material';
import { RenewalInfo } from '../products';
import { FC } from 'react';
import { products } from '../products';
import { Download } from '@mui/icons-material';

export const ExcelExport: FC<{
  current: RenewalInfo[];
  currentName: string;
  baseline: RenewalInfo[];
  baselineName: string;
  name: string;
  predicate?: (a: RenewalInfo, b: RenewalInfo) => boolean;
}> = ({ baseline, current, currentName, baselineName, name, predicate }) => {
  return (
    <Button
      startIcon={<Download />}
      size="small"
      onClick={() => exportFile(baseline, current, currentName, baselineName, name, predicate ?? ((a, b) => a.licenseId === b.licenseId))}
      title="Eksport til Excel"
      disabled={!current}>
      Last ned
    </Button>
  );
};

function exportFile(
  baseline: RenewalInfo[],
  current: RenewalInfo[],
  currentName: string,
  baselineName: string,
  name: string,
  predicate: (a: RenewalInfo, b: RenewalInfo) => boolean,
) {
  const diffs = baseline.filter((x) => {
    const renewal = current.find((r) => predicate(r, x));
    return renewal === undefined || x.total !== renewal.total;
  });

  const getValue = (list: RenewalInfo[], licenseId: number, selector: (sel: RenewalInfo) => number | undefined) => {
    const item = list.find((r) => r.licenseId === licenseId);
    if (item === undefined) {
      return 0;
    }
    return selector(item) ?? 0;
  };

  const getValues = (licenseId: number, selector: (sel: RenewalInfo) => number | undefined) => {
    const baselineValue = getValue(baseline, licenseId, selector);
    const currentValue = getValue(current, licenseId, selector);
    if (baselineValue === currentValue) return [null, null];
    return [baselineValue, currentValue];
  };

  const headings: (string | null)[] = ['Kunde', 'Abonnement', ...products.flatMap((x) => [x.name, null])];
  const subheadings: (string | null)[] = [null, null, ...products.flatMap((x) => [baselineName, currentName])];

  const data = [
    headings,
    subheadings,
    ...diffs.map((x) => {
      return [x.name, x.subscriptionOrderNumber, ...products.flatMap((p) => getValues(x.licenseId, p.selector))];
    }),
  ];

  const sheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, name);
  XLSX.writeFile(workbook, `${name}.xlsx`);
}

import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });
interface Props extends WithStyles<typeof styles> {
  labelWidth: number;
  label: string;
  text: string;
  fragment?: React.ReactNode;
  labelProps?: any;
  textProps?: any;
  preWrap?: boolean;
  multiline?: boolean;
}
export default withStyles(styles)(({ labelWidth, label, text, fragment, labelProps, textProps, preWrap, multiline, classes }: Props) => (
  <Grid container alignItems={multiline ? 'flex-start' : 'center'} className={classes.root}>
    <Typography variant="subtitle1" {...labelProps} style={{ width: labelWidth }}>
      {label}
    </Typography>
    <Typography variant="body2" component="div" style={preWrap && { whiteSpace: 'pre-wrap' }} {...textProps}>
      {fragment || text}
    </Typography>
  </Grid>
));

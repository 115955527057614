import React from 'react';
import { MainContentPanel } from '../components/MainContentPanel';
import { useQuery } from '@apollo/client';
import { GET_GUIDES } from './reports.graphql';
import LoadingSpinner from '../components/LoadingSpinner';
import GuideTable from './guides/GuideTable';

export function Usage() {
  const { loading, data } = useQuery(GET_GUIDES);
  return (
    <MainContentPanel>
      {(loading || !data) && <LoadingSpinner />}
      {data && data.guides && <GuideTable guides={data.guides} />}
    </MainContentPanel>
  );
}

import { gql } from '@apollo/client';
import { AdfsItem, ClientAuthorizationItem, LicenseAdfsItem } from '../../interfaces.graphql';
export const GET_ADFS = gql`
  query {
    adfs: allAdfsConfig {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;
export const GET_SINGLE_ADFS = gql`
  query ($id: ID!) {
    adfs: getSingleAdfs(id: $id) {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;
export const GET_SINGLE_ADFS_BY_BKS_ID = gql`
  query ($bksID: Int!) {
    adfs: getSingleAdfsByBksID(bksID: $bksID) {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;
export const CREATE_ADFS = gql`
  mutation ($input: CreateADFSInput!) {
    createADFS(input: $input) {
      ...LicenseAdfsItem
    }
  }
  ${LicenseAdfsItem}
`;
export const UPDATE_GENERAL_SETTING = gql`
  mutation UpdateGeneralSetting($input: UpdateGeneralSettingInput!) {
    updateGeneralSetting(input: $input) {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;
export const CREATE_CLIENT_AUTHORIZATION = gql`
  mutation ($input: CreateClientAuthorizationInput!) {
    createClientAuthorization(input: $input) {
      ...ClientAuthorizationItem
    }
  }
  ${ClientAuthorizationItem}
`;
export const UPDATE_ADVANCE_SETTING = gql`
  mutation ($input: UpdateAdvanceSettingInput!) {
    updateAdvanceSetting(input: $input) {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;
export const DELETE_CLIENT_AUTHORIZATION = gql`
  mutation ($input: DeleteClientAuthorizationInput!) {
    deleteClientAuthorization(input: $input) {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;
export const DELETE_ADFS = gql`
  mutation ($input: DeleteADFSInput!) {
    deleteADFS(input: $input) {
      ...AdfsItem
    }
  }
  ${AdfsItem}
`;

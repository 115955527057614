import XLSX from 'xlsx';
import { IconButton } from '@mui/material';
import { RenewalInfo } from '../products';
import { FC } from 'react';
import { products } from '../products';
import ExcelLogo from './../../assets/microsoft-excel.svg?react';

export const ExcelExport: FC<{
  current: RenewalInfo[];
  name: string;
}> = ({ current, name }) => {
  return (
    <IconButton size="small" onClick={() => exportFile(current, name)} title="Eksport til Excel" disabled={!current}>
      <ExcelLogo width={20} height={20} />
    </IconButton>
  );
};

function exportFile(current: RenewalInfo[], name: string) {
  const headings: (string | null)[] = ['Kunde', 'Abonnement', ...products.map((x) => x.name), 'Sum'];

  const data = [
    headings,
    ...current.map((x) => {
      return [x.name, x.subscriptionOrderNumber, ...products.map((p) => p.selector(x)), x.total];
    }),
  ];

  const sheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, name);
  XLSX.writeFile(workbook, `${name}.xlsx`);
}

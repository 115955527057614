import React, { FC, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Sidebar } from './Sidebar';
import { TopHeader } from './TopHeader';
import ScrollToTop from './ScrollToTop';

interface ShellProps {
  routes: RouteDef[];
}

export interface RouteDef {
  path: string;
  component: any;
  exact?: boolean;
  title?: string;
  icon?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
    zIndex: 1,
    overflow: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingRight: 0,
    marginTop: 56,
    [theme.breakpoints.up('sm')]: {
      marginTop: 64,
    },
  },
}));

export function Shell({ routes }: ShellProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <BrowserRouter>
      <>
        <ScrollToTop />
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <TopHeader handleDrawerToggle={handleDrawerToggle} />
            <Sidebar routes={routes} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
            <main className={classes.content}>
              <Switch>
                {routes.map((x, index) => {
                  return <Route key={index} exact={x.exact || true} path={x.path} component={x.component} />;
                })}
              </Switch>
            </main>
          </div>
        </div>
      </>
    </BrowserRouter>
  );
}

import makeStyles from '@mui/styles/makeStyles';
import { User, UserAccess } from '../../interfaces';
import { IconButton } from '@mui/material';
import XLSX from 'xlsx';
import { GET_USER_DATA_FOR_SUBSCRIPTION } from './UsersInLicense.graphql';
import { useQuery } from '@apollo/client';
import ExcelLogo from './../../assets/microsoft-excel.svg?react';

const useStyles = makeStyles((theme) => ({
  excelButton: {},
  excelIcon: {
    width: 24,
    height: 24,
  },
}));

export interface Props {
  users: UserAccess[];
  licenseId: number;
  subscriptionId: number;
}

export function ExcelExportLicenseUsers({ users, licenseId, subscriptionId }: Props) {
  const { loading, error, data } = useQuery<{ users: { id: string; userId: number; guideViewsCount: number }[] }>(GET_USER_DATA_FOR_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: { licenseId, subscriptionId },
  });

  const headings: string[] = ['Id', 'Navn', 'E-post', 'Sist innlogget', 'Sidevisninger'];

  const getGuideViews = (user: User) => {
    if (error) return 'Feilet';
    if (loading || !data) return 'Henter..';

    const userData = data.users.find((x) => x.userId === user.id);
    if (!userData) return 0;

    return userData.guideViewsCount;
  };

  const getSubscription = (subscription: number) => {
    switch (subscription) {
      case 9:
        return 'BFS';
      case 10:
        return 'BFSB';
      case 11:
        return 'BFSP';
      case 12:
        return 'BFSF';
      case 13:
        return 'BFSU';
      case 16:
        return 'BVN';
      case 19:
        return 'Prøve';
      default:
        return 'Ukjent';
    }
  };

  const top: string[] = ['LicenseId', licenseId.toString(), 'SubscriptionId', getSubscription(subscriptionId)];

  const lines: (string | number | null)[][] = users.map((item) => {
    var a: (string | number | null)[] = [];

    a.push(item.user.id);
    a.push(item.user.displayName);
    a.push(item.user.email);
    a.push(item.user.lastLoggedIn !== null ? item.user.lastLoggedIn.toString() : '');
    a.push(getGuideViews(item.user));

    return a;
  });

  lines.unshift(headings);
  lines.unshift(top);

  const classes = useStyles();
  return (
    <IconButton className={classes.excelButton} onClick={() => exportFile(lines)} title="Eksport til Excel" disabled={!users}>
      <ExcelLogo className={classes.excelIcon} />
    </IconButton>
  );
}

function exportFile(lines: (string | number | null)[][]) {
  const sheet = XLSX.utils.aoa_to_sheet(lines);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, 'Lisensbrukere');
  XLSX.writeFile(workbook, 'lisensbrukere.xlsx');
}

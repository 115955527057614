import { gql } from '@apollo/client';
import { OrderLineItem, SingleDocOrderLineItem } from '../../interfaces.graphql';
import { OrderItem } from '../../orders/overview/Orders.graphql';

export const ORDER_LINES_FROM_CHANGES = gql`
  query ($id: Int!) {
    orderLinesFromChanges(licenseId: $id) {
      ...OrderLineItem
    }
  }
  ${OrderLineItem}
`;

export const SINGLE_DOC_ORDER_LINES_FROM_CHANGES = gql`
  query ($id: Int!) {
    singleDocOrderLineFromChanges(licenseId: $id) {
      ...SingleDocOrderLineItem
    }
  }
  ${SingleDocOrderLineItem}
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ...OrderItem
    }
  }
  ${OrderItem}
`;

// export const SINGLE_DOC_CREATE_INVOICE = gql`
// mutation SingleDocCreateInvoice($input: SingleDocCreateInvoiceInput!) {
//     singleDocCreateInvoice(input: $input) {
//         ...Boolean
//     }
// }
// ${Boolean}`;

export const SINGLE_DOC_CREATE_INVOICE = gql`
  mutation SingleDocCreateInvoice($input: SingleDocCreateInvoiceInput!) {
    singleDocCreateInvoice(input: $input)
  }
`;

export const ANNUL_INVOICE = gql`
  mutation AnnulInvoice($input: AnnulInvoiceInput!) {
    annulInvoice(input: $input)
  }
`;

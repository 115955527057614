import { gql } from '@apollo/client';

export default gql`
  fragment AddressItem on Address {
    id
    address1
    address2
    city
    postalCode
    country
  }
`;

export let GET_COUNTRIES = gql`
  query {
    countries: allCountries {
      mostUsed
      all
    }
  }
`;
